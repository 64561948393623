import React, { useState, useEffect, useRef } from 'react';
import {
  Search,
  BookOpen,
  Loader2,
  AlertCircle,
  ChevronRight,
  Clock,
  ArrowLeft,
  Share2,
  Copy,
  Filter,
  Sun,
  Moon,
  LightbulbIcon,
  CheckCircleIcon,
  AlertTriangleIcon,
  ChevronDown,
  ChevronUp,
  ArrowUpCircle,
  Mail,
  Scale,
  ExternalLink,
  CheckCircle,
  MessageCircle,
  Database,
  Code2,
  Paintbrush,
  Component,
  Info,
  Link,
} from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import * as Dialog from '@radix-ui/react-dialog';
import * as Tooltip from '@radix-ui/react-tooltip';
import { FaLinkedin } from 'react-icons/fa';

const EXAMPLE_QUESTIONS = [
  // Family & Relationships
  "What are my rights in a de facto relationship in SA?",
  "How do I apply for child custody in South Australia?",
  "What's the process for divorce in Adelaide?",
  "How do I contest a will in South Australia?",
  "What are grandparents' rights to see grandchildren?",
  
  // Housing & Property
  "What are my rights as a tenant in SA?",
  "How much notice does a lessor need to give to end a lease?",
  "Can my real estate agent keep my bond?",
  "What are the rules for rental property inspections in SA?",
  "How do I dispute a rent increase notice?",
  
  // Employment
  "What's the minimum wage in South Australia?",
  "What are my rights if I'm made redundant?",
  "How much annual leave am I entitled to in SA?",
  "What are the WHS requirements for my workplace?",
  "Can my employer make me work on public holidays?",
  
  // Traffic & Driving
  "How many demerit points until I lose my licence in SA?",
  "How do I dispute a speeding fine in Adelaide?",
  "What are the rules for P1 and P2 drivers?",
  "Can I use my mobile as a GPS while driving?",
  "What happens if I'm caught drink driving in SA?",
  
  // Neighbourhood
  "What are the noise restrictions in Adelaide?",
  "How do I report a noisy neighbour to council?",
  "What are the rules for fence disputes in SA?",
  "Can my neighbour's tree overhang my property?",
  "What are the council regulations for keeping pets?",
];

const MAX_HISTORY = 10;
const MAX_SUGGESTIONS = 5;

const DisclaimerModal = ({ isOpen, onClose }) => {
  return (
    <Dialog.Root open={isOpen}>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 bg-black/50 backdrop-blur-sm z-50" />
        <Dialog.Content 
          className="fixed top-1/2 left-1/2 max-h-[85vh] w-[90vw] max-w-[500px] 
          transform -translate-x-1/2 -translate-y-1/2 rounded-lg bg-white dark:bg-gray-900 border 
          border-gray-300 dark:border-gray-700 p-6 shadow-lg z-50 overflow-y-auto focus:outline-none"
          aria-describedby="disclaimer-description"
        >
          <Dialog.Title className="text-xl font-bold text-red-600 dark:text-red-500 flex items-center gap-2 mb-4">
            <AlertCircle className="h-5 w-5" aria-hidden="true" />
            Important Notice
          </Dialog.Title>
          
          <div className="space-y-4">
            <div className="bg-yellow-100 dark:bg-yellow-900/30 border border-yellow-300 dark:border-yellow-700/50 rounded-lg p-4">
              <p className="text-yellow-900 dark:text-yellow-200 text-sm" id="disclaimer-description">
                This is an AI-powered tool providing information about South Australian legislation. 
                It is not a substitute for professional legal advice.
              </p>
            </div>
            
            <div className="space-y-2">
              <h3 className="font-semibold text-gray-800 dark:text-gray-200">By using this service, you acknowledge:</h3>
              <ul className="space-y-2 text-sm text-gray-800 dark:text-gray-300">
                <li className="flex items-start gap-2">
                  <span className="text-blue-600 dark:text-blue-400 mt-1" aria-hidden="true">•</span>
                  This is a research tool only, not a source of legal advice
                </li>
                <li className="flex items-start gap-2">
                  <span className="text-blue-600 dark:text-blue-400 mt-1" aria-hidden="true">•</span>
                  Information may be incomplete or outdated
                </li>
                <li className="flex items-start gap-2">
                  <span className="text-blue-600 dark:text-blue-400 mt-1" aria-hidden="true">•</span>
                  You should consult qualified legal professionals for specific advice
                </li>
                <li className="flex items-start gap-2">
                  <span className="text-blue-600 dark:text-blue-400 mt-1" aria-hidden="true">•</span>
                  No responsibility is taken for decisions made based on this information
                </li>
              </ul>
            </div>
          </div>

          <div className="mt-6 space-y-4">
            <button 
              onClick={onClose}
              className="w-full bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded-lg transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              I Understand and Accept
            </button>
            <p className="text-xs text-gray-700 dark:text-gray-400 text-center">
              Created by Hamish Cameron in Adelaide, South Australia
            </p>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};


const StatsDisplay = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const stats = [
    { count: 570, type: 'Acts' },
    { count: 468, type: 'Regulations' }
  ];

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prev) => (prev + 1) % stats.length);
    }, 3000);
    return () => clearInterval(timer);
  }, [stats.length]);

  return (
    <p className="text-sm text-gray-600 dark:text-gray-700">
      Searching across{' '}
      <span className="relative h-6 inline-flex align-baseline overflow-hidden">
        <AnimatePresence mode="wait">
          <motion.span
            key={currentIndex}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
            className="inline-block whitespace-nowrap"
            aria-live="polite"
          >
            {stats[currentIndex].count.toLocaleString()} {stats[currentIndex].type}
          </motion.span>
        </AnimatePresence>
      </span>
      {' '}and{' '}
      <span className="text-gray-700 dark:text-gray-400">68 million</span> characters of legislation and regulations
    </p>
  );
};

const ExampleQuestionsGrid = ({ onQuestionClick, setShowDisclaimer, setShowAbout }) => {
  const [randomExamples, setRandomExamples] = useState([]);
  
  useEffect(() => {
    const getRandomQuestions = () => {
      const shuffled = [...EXAMPLE_QUESTIONS].sort(() => 0.5 - Math.random());
      return shuffled.slice(0, 5);
    };
    
    setRandomExamples(getRandomQuestions());
    const interval = setInterval(() => {
      setRandomExamples(getRandomQuestions());
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="space-y-8">
      <div className="text-center space-y-4">
        <p className="text-sm text-gray-600 dark:text-gray-400">Popular questions:</p>
        <div className="flex flex-wrap gap-2 justify-center">
          {randomExamples.map((example, index) => (
            <button
              key={index}
              onClick={() => onQuestionClick(example)}
              className="px-4 py-2 bg-gray-100 dark:bg-gray-800 hover:bg-gray-200 dark:hover:bg-gray-700 rounded-full text-sm text-gray-700 dark:text-gray-300 transition-colors border border-gray-300 dark:border-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
              aria-label={`Search for: ${example}`}
            >
              {example}
            </button>
          ))}
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="bg-white dark:bg-gray-800 rounded-lg p-6 shadow-sm border border-gray-200 dark:border-gray-700">
          <h2 className="text-xl font-semibold mb-4 flex items-center gap-2">
            <BookOpen className="h-5 w-5 text-blue-500" />
            About Legal Oracle
          </h2>
          <p className="text-gray-600 dark:text-gray-400 leading-relaxed">
            Legal Oracle is South Australia's premier AI-powered legal research assistant. We help you navigate SA's complex legal landscape by providing accurate, up-to-date information from official legislation and regulations. Perfect for citizens, business owners, and legal professionals seeking quick, reliable legal information.
          </p>
        </div>

        <div className="bg-white dark:bg-gray-800 rounded-lg p-6 shadow-sm border border-gray-200 dark:border-gray-700">
          <h2 className="text-xl font-semibold mb-4">Why Use Legal Oracle?</h2>
          <ul className="space-y-3 text-gray-600 dark:text-gray-400">
            <li className="flex items-center gap-2">
              <span className="w-2 h-2 bg-blue-500 rounded-full" />
              Searches across 570+ Acts and 468+ Regulations
            </li>
            <li className="flex items-center gap-2">
              <span className="w-2 h-2 bg-blue-500 rounded-full" />
              Updated with latest legislative changes
            </li>
            <li className="flex items-center gap-2">
              <span className="w-2 h-2 bg-blue-500 rounded-full" />
              Plain English explanations of complex laws
            </li>
            <li className="flex items-center gap-2">
              <span className="w-2 h-2 bg-blue-500 rounded-full" />
              Citations to relevant legislation
            </li>
          </ul>
        </div>

      </div>
	  <Footer setShowDisclaimer={setShowDisclaimer} setShowAbout={setShowAbout} />
	  
    </div>
  );
};

const AboutModal = ({ isOpen, onClose }) => {
  return (
    <Dialog.Root open={isOpen}>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 bg-black/50 backdrop-blur-sm z-50" />
        <Dialog.Content
          className="fixed top-1/2 left-1/2 max-h-[85vh] w-[90vw] max-w-[600px]
          transform -translate-x-1/2 -translate-y-1/2 rounded-lg bg-white dark:bg-gray-900 border
          border-gray-300 dark:border-gray-700 p-6 shadow-lg z-50 overflow-y-auto focus:outline-none"
        >
          <Dialog.Title className="text-xl font-bold text-gray-900 dark:text-gray-100 flex items-center gap-2 mb-4">
            <BookOpen className="h-5 w-5 text-blue-500" aria-hidden="true" />
            About Legal Oracle
          </Dialog.Title>

          <div className="space-y-6">
            {/* Overview Section */}
            <section>
              <h3 className="text-lg font-semibold mb-2">What is Legal Oracle?</h3>
              <p className="text-gray-600 dark:text-gray-400">
                Legal Oracle is an AI-powered legal research assistant that helps you navigate South
                Australian legislation. It provides accurate, up-to-date information by searching
                across hundreds of acts and regulations.
              </p>
            </section>

            {/* Official Resources */}
            <section>
              <h3 className="text-lg font-semibold mb-2">Official Resources</h3>
              <div className="space-y-3">
                <a
                  href="https://www.legislation.sa.gov.au/legislation"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center gap-2 text-blue-600 hover:text-blue-500 dark:text-blue-400 dark:hover:text-blue-300"
                >
                  <ExternalLink className="h-4 w-4" />
                  <span>South Australian Legislation</span>
                </a>
                <a
                  href="https://www.legislation.sa.gov.au/browse-regulations"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center gap-2 text-blue-600 hover:text-blue-500 dark:text-blue-400 dark:hover:text-blue-300"
                >
                  <ExternalLink className="h-4 w-4" />
                  <span>South Australian Regulations</span>
                </a>
                <p className="text-sm text-gray-500 dark:text-gray-500 mt-1">
                  Always verify information against official sources
                </p>
              </div>
            </section>

            {/* Technology Stack section with ChatGPT included */}
            <section>
              <h3 className="text-lg font-semibold mb-2">Technology Stack</h3>
              <div className="space-y-4 text-gray-600 dark:text-gray-400">
                <p>Built using cutting-edge AI and search technology:</p>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <a
                    href="https://openai.com/chatgpt"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center gap-2 p-3 rounded-lg bg-gray-100 dark:bg-gray-800/50 hover:bg-gray-200 dark:hover:bg-gray-800 transition-colors group"
                  >
                    <div className="w-8 h-8 flex items-center justify-center rounded-full bg-red-100 dark:bg-red-900/20">
                      <MessageCircle className="h-5 w-5 text-red-600 dark:text-red-400" />
                    </div>
                    <div>
                      <div className="font-medium group-hover:text-blue-600 dark:group-hover:text-blue-400">
                        ChatGPT
                      </div>
                      <div className="text-sm text-gray-500">AI Assistant</div>
                    </div>
                  </a>
                  <a
                    href="https://anthropic.com/claude"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center gap-2 p-3 rounded-lg bg-gray-100 dark:bg-gray-800/50 hover:bg-gray-200 dark:hover:bg-gray-800 transition-colors group"
                  >
                    <div className="w-8 h-8 flex items-center justify-center rounded-full bg-purple-100 dark:bg-purple-900/20">
                      <LightbulbIcon className="h-5 w-5 text-purple-600 dark:text-purple-400" />
                    </div>
                    <div>
                      <div className="font-medium group-hover:text-blue-600 dark:group-hover:text-blue-400">
                        Claude AI
                      </div>
                      <div className="text-sm text-gray-500">Natural Language Processing</div>
                    </div>
                  </a>
                  <a
                    href="https://voyage.ai/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center gap-2 p-3 rounded-lg bg-gray-100 dark:bg-gray-800/50 hover:bg-gray-200 dark:hover:bg-gray-800 transition-colors group"
                  >
                    <div className="w-8 h-8 flex items-center justify-center rounded-full bg-blue-100 dark:bg-blue-900/20">
                      <Search className="h-5 w-5 text-blue-600 dark:text-blue-400" />
                    </div>
                    <div>
                      <div className="font-medium group-hover:text-blue-600 dark:group-hover:text-blue-400">
                        Voyage AI
                      </div>
                      <div className="text-sm text-gray-500">Text Embeddings</div>
                    </div>
                  </a>
                  <a
                    href="https://www.pinecone.io/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center gap-2 p-3 rounded-lg bg-gray-100 dark:bg-gray-800/50 hover:bg-gray-200 dark:hover:bg-gray-800 transition-colors group"
                  >
                    <div className="w-8 h-8 flex items-center justify-center rounded-full bg-green-100 dark:bg-green-900/20">
                      <Database className="h-5 w-5 text-green-600 dark:text-green-400" />
                    </div>
                    <div>
                      <div className="font-medium group-hover:text-blue-600 dark:group-hover:text-blue-400">
                        Pinecone
                      </div>
                      <div className="text-sm text-gray-500">Vector Database</div>
                    </div>
                  </a>
                  <a
                    href="https://react.dev/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center gap-2 p-3 rounded-lg bg-gray-100 dark:bg-gray-800/50 hover:bg-gray-200 dark:hover:bg-gray-800 transition-colors group"
                  >
                    <div className="w-8 h-8 flex items-center justify-center rounded-full bg-cyan-100 dark:bg-cyan-900/20">
                      <Code2 className="h-5 w-5 text-cyan-600 dark:text-cyan-400" />
                    </div>
                    <div>
                      <div className="font-medium group-hover:text-blue-600 dark:group-hover:text-blue-400">
                        React
                      </div>
                      <div className="text-sm text-gray-500">Frontend Framework</div>
                    </div>
                  </a>
                  <a
                    href="https://tailwindcss.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center gap-2 p-3 rounded-lg bg-gray-100 dark:bg-gray-800/50 hover:bg-gray-200 dark:hover:bg-gray-800 transition-colors group"
                  >
                    <div className="w-8 h-8 flex items-center justify-center rounded-full bg-teal-100 dark:bg-teal-900/20">
                      <Paintbrush className="h-5 w-5 text-teal-600 dark:text-teal-400" />
                    </div>
                    <div>
                      <div className="font-medium group-hover:text-blue-600 dark:group-hover:text-blue-400">
                        Tailwind CSS
                      </div>
                      <div className="text-sm text-gray-500">Styling Framework</div>
                    </div>
                  </a>
                  <a
                    href="https://ui.shadcn.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center gap-2 p-3 rounded-lg bg-gray-100 dark:bg-gray-800/50 hover:bg-gray-200 dark:hover:bg-gray-800 transition-colors group"
                  >
                    <div className="w-8 h-8 flex items-center justify-center rounded-full bg-indigo-100 dark:bg-indigo-900/20">
                      <Component className="h-5 w-5 text-indigo-600 dark:text-indigo-400" />
                    </div>
                    <div>
                      <div className="font-medium group-hover:text-blue-600 dark:group-hover:text-blue-400">
                        shadcn/ui
                      </div>
                      <div className="text-sm text-gray-500">UI Components</div>
                    </div>
                  </a>
                </div>
              </div>
            </section>

            {/* Data Freshness */}
            <section>
              <h3 className="text-lg font-semibold mb-2">Data Freshness</h3>
              <p className="text-gray-600 dark:text-gray-400">
                Legislative database last updated:{' '}
                <span className="font-medium text-blue-600 dark:text-blue-400">
                  March 15, 2024
                </span>
              </p>
              <p className="text-sm text-gray-500 dark:text-gray-500 mt-1">
                We regularly update our database to ensure accuracy and completeness.
              </p>
            </section>

            {/* Strengths */}
            <section>
              <h3 className="text-lg font-semibold mb-2">Strengths</h3>
              <ul className="space-y-2 text-gray-600 dark:text-gray-400">
                <li className="flex items-start gap-2">
                  <CheckCircle className="h-5 w-5 text-green-500 mt-0.5" />
                  <span>Fast, instant access to SA legislation</span>
                </li>
                <li className="flex items-start gap-2">
                  <CheckCircle className="h-5 w-5 text-green-500 mt-0.5" />
                  <span>Comprehensive coverage of acts and regulations</span>
                </li>
                <li className="flex items-start gap-2">
                  <CheckCircle className="h-5 w-5 text-green-500 mt-0.5" />
                  <span>Plain English explanations with legal citations</span>
                </li>
                <li className="flex items-start gap-2">
                  <CheckCircle className="h-5 w-5 text-green-500 mt-0.5" />
                  <span>Structured, consistent response format</span>
                </li>
              </ul>
            </section>

            {/* Limitations */}
            <section>
              <h3 className="text-lg font-semibold mb-2">Limitations</h3>
              <ul className="space-y-2 text-gray-600 dark:text-gray-400">
                <li className="flex items-start gap-2">
                  <AlertCircle className="h-5 w-5 text-yellow-500 mt-0.5" />
                  <span>Not a substitute for professional legal advice</span>
                </li>
                <li className="flex items-start gap-2">
                  <AlertCircle className="h-5 w-5 text-yellow-500 mt-0.5" />
                  <span>May not capture very recent legislative changes</span>
                </li>
                <li className="flex items-start gap-2">
                  <AlertCircle className="h-5 w-5 text-yellow-500 mt-0.5" />
                  <span>Complex legal scenarios require human expertise</span>
                </li>
                <li className="flex items-start gap-2">
                  <AlertCircle className="h-5 w-5 text-yellow-500 mt-0.5" />
                  <span>Focuses only on SA legislation, not other jurisdictions</span>
                </li>
              </ul>
            </section>

            {/* Contact Section */}
            <section>
              <h3 className="text-lg font-semibold mb-2">Contact</h3>
              <div className="space-y-2">
                <a
                  href="mailto:contact@legaloracle.ai"
                  className="flex items-center gap-2 text-blue-600 hover:text-blue-500 dark:text-blue-400 dark:hover:text-blue-300"
                >
                  <Mail className="h-5 w-5" />
                  <span>contact@legaloracle.ai</span>
                </a>
                <p className="text-sm text-gray-500 dark:text-gray-500">
                  For questions, feedback, or support
                </p>
              </div>
            </section>
          </div>

          <div className="mt-6 flex justify-end">
            <button
              onClick={onClose}
              className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              Close
            </button>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};


const Footer = ({ setShowDisclaimer, setShowAbout }) => (
  <footer className="mt-12 pt-6 border-t border-gray-300 dark:border-gray-800">
    <div className="flex flex-col items-center justify-center gap-4 text-sm text-gray-600 dark:text-gray-400">
      <div className="flex items-center gap-2">
        <span>Created by Hamish Cameron in Adelaide, South Australia</span>
      </div>
      <div className="flex items-center gap-4">
        <a
          href="https://www.linkedin.com/in/hamishcameron/"
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center gap-1 text-blue-600 hover:text-blue-500 dark:text-blue-400 dark:hover:text-blue-300 transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500"
          title="Connect on LinkedIn"
          aria-label="Connect on LinkedIn"
        >
          <FaLinkedin className="w-6 h-6" aria-hidden="true" />
        </a>
        <a
          href="mailto:contact@legaloracle.ai"
          className="flex items-center gap-1 text-blue-600 hover:text-blue-500 dark:text-blue-400 dark:hover:text-blue-300 transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500"
          title="Email Us"
          aria-label="Email Us"
        >
          <Mail className="w-6 h-6" aria-hidden="true" />
        </a>
      </div>
      <div className="flex items-center gap-4">
        <button
          onClick={() => setShowAbout(true)}
          className="flex items-center gap-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500"
          aria-label="About Legal Oracle"
        >
          <Info className="h-5 w-5" aria-hidden="true" />
          About Legal Oracle
        </button>
        <button
          onClick={() => setShowDisclaimer(true)}
          className="flex items-center gap-2 px-4 py-2 bg-yellow-500 text-white rounded-lg hover:bg-yellow-600 transition-colors focus:outline-none focus:ring-2 focus:ring-yellow-500"
          aria-label="View Disclaimer"
        >
          <AlertCircle className="h-5 w-5" aria-hidden="true" />
          View Disclaimer
        </button>
      </div>
    </div>
  </footer>
);



const SectionPlaceholder = ({ 
  title, 
  icon: Icon, 
  isLoading, 
  content, 
  isCollapsed, 
  onToggleCollapse,
  renderCustomContent,
  isActive, // New prop to determine if the section is actively being written to
}) => (
  <motion.div 
    className={`relative rounded-lg overflow-hidden border-2 transition-all duration-300 ${
      isActive ? 'border-green-500' : isLoading ? 'border-blue-500' : 'border-gray-300 dark:border-gray-600'
    }`}
    initial={{ scale: 0.95, opacity: 0 }}
    animate={{ scale: 1, opacity: 1 }}
    exit={{ scale: 0.95, opacity: 0 }}
    whileHover={{
      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)',
      borderColor: isActive ? '#34d399' : '#60a5fa', // Green highlight for active
    }}
    transition={{ duration: 0.5 }}
  >
    <div
      className={`p-4 flex items-center gap-3 border-b border-gray-300 dark:border-gray-700 ${
        isLoading ? 'animate-border-highlight' : ''
      }`}
    >
      <Icon className={`h-5 w-5 text-blue-500 dark:text-blue-400`} aria-hidden="true" />
      <h2 className="font-bold text-gray-800 dark:text-gray-100 flex-grow">{title}</h2>
      {isLoading && (
        <Loader2 className="h-4 w-4 text-gray-700 dark:text-gray-400 animate-spin mr-2" aria-label="Loading" />
      )}
      {!isLoading && (
        <button 
          onClick={onToggleCollapse} 
          className="text-gray-700 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-200 transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500"
          aria-label={isCollapsed ? `Expand ${title}` : `Collapse ${title}`}
        >
          {isCollapsed ? <ChevronDown className="h-5 w-5" aria-hidden="true" /> : <ChevronUp className="h-5 w-5" aria-hidden="true" />}
        </button>
      )}
    </div>
    <AnimatePresence initial={false}>
      {!isCollapsed && (
        <motion.div
          key="content"
          initial={{ height: 0, opacity: 0 }}
          animate={{ height: 'auto', opacity: 1 }}
          exit={{ height: 0, opacity: 0 }}
          transition={{ duration: 0.5 }}
          className="overflow-hidden"
        >
          <div className="p-4">
            {isLoading ? (
              <p className="text-gray-700 dark:text-gray-400 italic">Loading {title.toLowerCase()}...</p>
            ) : renderCustomContent ? (
              renderCustomContent()
            ) : (
              <div className="space-y-2">
                {content?.map((line, lineIndex) => {
                  if (!line?.trim()) return null;
                  const trimmedLine = line.trim();
                  const isNumberedPoint = /^\d+\./.test(trimmedLine);
                  const isBulletPoint = /^-\s*/.test(trimmedLine);

                  return (
                    <div
                      key={lineIndex}
                      className={`
                        text-gray-800 dark:text-gray-200
                        ${isNumberedPoint ? 'flex gap-2 items-start' : ''}
                        ${isBulletPoint ? 'ml-4 flex gap-2 items-start' : ''}
                      `}
                    >
                      {isNumberedPoint && (
                        <span className="text-blue-500 dark:text-blue-400 font-medium">
                          {trimmedLine.match(/^\d+\./)[0]}
                        </span>
                      )}
                      {isBulletPoint && (
                        <span className="text-blue-500 dark:text-blue-400 mt-1.5" aria-hidden="true">•</span>
                      )}
                      <span>
                        {isNumberedPoint
                          ? trimmedLine.replace(/^\d+\.\s*/, '').trim()
                          : isBulletPoint
                          ? trimmedLine.replace(/^-\s*/, '').trim()
                          : trimmedLine}
                      </span>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  </motion.div>
);




const FormattedAnswer = ({ 
  answer = "", 
  loadingSections, 
  result, 
  sectionsConfig,
  collapsedSections,
  onToggleCollapse 
}) => {
  const normalizedAnswer = (answer || "").replace(/\r\n/g, '\n').replace(/\r/g, '\n');
  const sectionHeaderRegex = /^\d+\.\s*(DIRECT ANSWER|LEGAL BASIS|KEY REQUIREMENTS|LIMITATIONS AND EXCEPTIONS):$/gm;
  const matches = [...normalizedAnswer.matchAll(sectionHeaderRegex)];
  const formattedSections = [];

  for (let i = 0; i < matches.length; i++) {
    const match = matches[i];
    const sectionTitle = match[1];
    const startIndex = match.index + match[0].length;
    const endIndex = i < matches.length - 1 ? matches[i + 1].index : normalizedAnswer.length;
    const sectionContent = normalizedAnswer.substring(startIndex, endIndex).trim();
    const contentLines = sectionContent.split('\n').filter(line => line.trim() !== '');

    formattedSections.push({
      title: sectionTitle,
      content: contentLines,
      icon: sectionsConfig[sectionTitle].icon,
    });
  }

  // All sections in order
  const allSections = ['DIRECT ANSWER', 'LEGAL BASIS', 'KEY REQUIREMENTS', 'LIMITATIONS AND EXCEPTIONS', 'SOURCES'];

  return (
    <div className="space-y-6">
      {allSections.map((sectionTitle, index) => {
        const section = formattedSections.find(s => s.title === sectionTitle);
        const isLoading = loadingSections.includes(sectionTitle) && !section;
        const isCollapsed = collapsedSections[sectionTitle];

        if (sectionTitle === 'SOURCES' && result?.sources) {
          return (
            <SectionPlaceholder
              key={sectionTitle}
              title={sectionTitle}
              icon={sectionsConfig[sectionTitle].icon}
              isLoading={isLoading}
              isCollapsed={isCollapsed}
              onToggleCollapse={() => onToggleCollapse(sectionTitle)}
              renderCustomContent={() => (
                <div className="space-y-3">
                  {result.sources.map((source, index) => (
                    <div
                      key={index}
                      className="bg-gray-200 dark:bg-gray-800/50 rounded-lg border border-gray-300 dark:border-gray-700 p-3"
                    >
                      <div className="flex items-start justify-between gap-4">
                        <div className="flex-grow">
                          <h4 className="font-medium text-gray-800 dark:text-gray-200">
                            {source.title}
                          </h4>
                          {source.pages?.length > 0 && (
                            <p className="text-sm text-gray-600 dark:text-gray-400 mt-1">
                              Pages cited: {source.pages.join(", ")}
                            </p>
                          )}
                        </div>
                        {source.url && (
                          <a
                            href={source.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="flex items-center gap-2 px-3 py-2 text-sm bg-blue-600/20 text-blue-600 dark:text-blue-400 rounded-md hover:bg-blue-600/30 transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500"
                          >
                            <ExternalLink className="h-4 w-4" aria-hidden="true" />
                            View Online
                          </a>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            />
          );
        }

        return (
          <SectionPlaceholder
            key={sectionTitle}
            title={sectionTitle}
            icon={sectionsConfig[sectionTitle].icon}
            isLoading={isLoading}
            content={section ? section.content : null}
            isCollapsed={isCollapsed}
            onToggleCollapse={() => onToggleCollapse(sectionTitle)}
          />
        );
      })}
    </div>
  );
};




export default function App() {
  // State variables
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const [question, setQuestion] = useState('');
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [progressMessage, setProgressMessage] = useState('');
  const [error, setError] = useState(null);
  const [history, setHistory] = useState([]);
  const [showHistory, setShowHistory] = useState(false);
  const [actionMessage, setActionMessage] = useState('');
  const [connected, setConnected] = useState(false);
  const [currentSection, setCurrentSection] = useState(null);
  const [loadingSections, setLoadingSections] = useState([]);
  const [showScrollToTop, setShowScrollToTop] = useState(false);
  const [theme, setTheme] = useState(() => localStorage.getItem('theme') || 'light');
  const [fontSize, setFontSize] = useState(() => localStorage.getItem('fontSize') || 'text-base');

  const [suggestions, setSuggestions] = useState([]);
  
  const [showAbout, setShowAbout] = useState(false); // Add this line

  const wsRef = useRef(null);
  const manualCloseRef = useRef(false);

  const MAX_HISTORY = 10;
  const MAX_SUGGESTIONS = 5;
  const MAX_RECONNECT_ATTEMPTS = 5;
  const [reconnectAttempts, setReconnectAttempts] = useState(0);

  // Add sections configuration
  const sectionsConfig = {
    'DIRECT ANSWER': { icon: LightbulbIcon },
    'LEGAL BASIS': { icon: BookOpen },
    'KEY REQUIREMENTS': { icon: CheckCircleIcon },
    'LIMITATIONS AND EXCEPTIONS': { icon: AlertTriangleIcon },
    'SOURCES': { icon: Link }
  };

  // Add collapsed sections state
  const [collapsedSections, setCollapsedSections] = useState({});

  // Add toggle collapse function
  const toggleCollapse = (sectionTitle) => {
    setCollapsedSections(prev => ({
      ...prev,
      [sectionTitle]: !prev[sectionTitle]
    }));
  };

	// Consolidate useEffect for theme and font size
	useEffect(() => {
	  const savedTheme = localStorage.getItem('theme') || 'light';
	  const savedFontSize = localStorage.getItem('fontSize') || 'text-base';

	  setTheme(savedTheme);
	  setFontSize(savedFontSize);
	}, []); // Only run once on mount

	useEffect(() => {
	  // Save theme and fontSize to localStorage only on updates
	  if (theme) {
		localStorage.setItem('theme', theme);
	  }
	}, [theme]);

	useEffect(() => {
	  if (fontSize) {
		localStorage.setItem('fontSize', fontSize);
	  }
	}, [fontSize]);

	// Apply theme class to <html> element
	useEffect(() => {
	  const root = window.document.documentElement;
	  root.classList.toggle('dark', theme === 'dark');
	}, [theme]);


  // WebSocket connection and event handlers
  useEffect(() => {
    connectWebSocket();

    return () => {
      manualCloseRef.current = true;
      if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
        wsRef.current.close();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const connectWebSocket = () => {
    const websocket = new WebSocket('wss://2lv389ryo5.execute-api.ap-southeast-2.amazonaws.com/production');

    websocket.onopen = () => {
      console.log('WebSocket Connected');
      setConnected(true);
      setReconnectAttempts(0);
    };

    websocket.onclose = (event) => {
      console.log('WebSocket Closed:', event);
      setConnected(false);

      if (!manualCloseRef.current && reconnectAttempts < MAX_RECONNECT_ATTEMPTS) {
        const timeout = Math.pow(2, reconnectAttempts) * 1000; // Exponential backoff
        //console.log(`Attempting to reconnect in ${timeout / 1000} seconds...`);
        setTimeout(() => {
          setReconnectAttempts(prev => prev + 1);
          connectWebSocket();
        }, timeout);
      } else if (reconnectAttempts >= MAX_RECONNECT_ATTEMPTS) {
        setError('Unable to reconnect. Please refresh the page or try again later.');
      }
    };

    websocket.onerror = (error) => {
      console.error('WebSocket Error:', error);
      setError('Connection error. Please try again later.');
      websocket.close();
    };

	websocket.onmessage = (event) => {
	  try {
		if (!event.data) {
		  console.warn('Received empty message');
		  return;
		}

		const data = JSON.parse(event.data);

		if (data.error) {
		  setError(data.error);
		  setLoading(false);
		} else if (data.chunk !== undefined) {
		  setResult(prev => ({
			...(prev || {}),
			question,
			answer: ((prev?.answer) || '') + data.chunk,
		  }));

		  // Detect section changes
		  const sections = {
			'DIRECT ANSWER': '1. DIRECT ANSWER:',
			'LEGAL BASIS': '2. LEGAL BASIS:',
			'KEY REQUIREMENTS': '3. KEY REQUIREMENTS:',
			'LIMITATIONS AND EXCEPTIONS': '4. LIMITATIONS AND EXCEPTIONS:'
		  };

		  Object.entries(sections).forEach(([section, header]) => {
			if (data.chunk.includes(header)) {
			  setCurrentSection(section);
			  setLoadingSections(prevSections => {
				const updatedSections = [...prevSections];
				if (!updatedSections.includes(section)) {
				  updatedSections.push(section);
				}
				return updatedSections;
			  });
			}
		  });
		} else if (data.sources) {
		  // Update result with sources
		  setResult(prev => ({
			...(prev || {}),
			sources: data.sources,
		  }));

		  // If this is the final message
		  if (data.done) {
			setResult(prev => {
			  const finalResult = {
				question,
				answer: prev?.answer || '',
				sources: prev?.sources || [],
				timestamp: new Date().toISOString(),
			  };
			  setHistory(prevHistory => [finalResult, ...prevHistory].slice(0, MAX_HISTORY));
			  setLoading(false);
			  setCurrentSection(null);
			  setLoadingSections([]);
			  return finalResult;
			});
		  }
		} else {
		  console.log('Unhandled message type:', data);
		}
	  } catch (err) {
		console.error('Failed to parse message:', event.data);
		console.error('Parse error:', err);
		setError('Error processing server response');
		setLoading(false);
	  }
	};

    wsRef.current = websocket;
  };

	useEffect(() => {
	  try {
		const savedHistory = localStorage.getItem('legalOracleHistory');
		if (savedHistory) {
		  const parsedHistory = JSON.parse(savedHistory);
		  if (Array.isArray(parsedHistory)) {
			// Validate objects in the array
			const validHistory = parsedHistory.filter(
			  (item) =>
				typeof item.question === 'string' &&
				typeof item.answer === 'string' &&
				typeof item.timestamp === 'string'
			);
			setHistory(validHistory);
		  } else {
			console.warn('History format invalid. Initializing empty.');
			setHistory([]);
		  }
		} else {
		  setHistory([]);
		}
	  } catch (err) {
		console.error('Failed to load history:', err);
		setHistory([]);
	  }
	}, []);


	useEffect(() => {
	  try {
		const savedHistory = localStorage.getItem('legalOracleHistory');
		if (savedHistory) {
		  setHistory(JSON.parse(savedHistory));
		} else {
		  setHistory([]); // Initialize with an empty array if no history is saved
		}
	  } catch (err) {
		console.error('Failed to load history:', err);
		setHistory([]); // Fallback to an empty array on error
	  }
	}, []);

  // Save theme and font size to localStorage
  useEffect(() => {
    localStorage.setItem('theme', theme);
  }, [theme]);

  useEffect(() => {
    localStorage.setItem('fontSize', fontSize);
  }, [fontSize]);

  // Apply theme class to <html> element
  useEffect(() => {
    const root = window.document.documentElement;
    if (theme === 'dark') {
      root.classList.add('dark');
    } else {
      root.classList.remove('dark');
    }
  }, [theme]);

  // Action message timeout
  useEffect(() => {
    if (actionMessage) {
      const timer = setTimeout(() => setActionMessage(''), 3000);
      return () => clearTimeout(timer);
    }
  }, [actionMessage]);

  // Show disclaimer on first load or after 24 hours
  useEffect(() => {
    const lastAccepted = localStorage.getItem('legalOracle_accepted_time');
    const now = new Date().getTime();

    if (!lastAccepted || now - parseInt(lastAccepted, 10) > 24 * 60 * 60 * 1000) {
      setShowDisclaimer(true);
    }
  }, []);

  // Progress messages during loading
  useEffect(() => {
    if (loading) {
      const progressMessages = [
        "Analyzing your question...",
        "Fetching relevant documents...",
        "Processing information...",
        "Generating answer...",
      ];
      let index = 0;
      setProgressMessage(progressMessages[index]);

      const interval = setInterval(() => {
        index = (index + 1) % progressMessages.length;
        setProgressMessage(progressMessages[index]);
      }, 3000);

      return () => clearInterval(interval);
    }
  }, [loading]);

  // Handle scroll to top visibility
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) {
        setShowScrollToTop(true);
      } else {
        setShowScrollToTop(false);
      }
    };
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Update suggestions based on input
  useEffect(() => {
    if (question.trim().length > 0) {
      const filteredSuggestions = history
        .map(item => item.question)
        .filter(q => q.toLowerCase().includes(question.toLowerCase()))
        .slice(0, MAX_SUGGESTIONS);
      setSuggestions(filteredSuggestions);
    } else {
      setSuggestions([]);
    }
  }, [question, history]);

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Function to handle disclaimer acceptance
  const handleDisclaimerAccept = () => {
    localStorage.setItem('legalOracle_accepted_time', new Date().getTime().toString());
    setShowDisclaimer(false);
  };

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!question.trim() || !connected) return;

    setLoading(true);
    setError(null);
    setShowHistory(false);
    setResult(null);
    setCurrentSection(null);
    setLoadingSections([]);
    setProgressMessage('Analyzing your question...');

    try {
      wsRef.current.send(JSON.stringify({
        action: 'query',
        body: {
          question: question
        }
      }));
    } catch (error) {
      console.error('Send error:', error);
      setError('Failed to send question. Please try again.');
      setLoading(false);
    }
  };

  // Function to handle example question click
  const handleExampleClick = (exampleQuestion) => {
    setQuestion(exampleQuestion);
    setShowHistory(false);
    setError(null);
    setSuggestions([]);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Function to handle history item click
  const handleHistoryItemClick = (historyItem) => {
    setResult(historyItem);
    setQuestion('');
    setShowHistory(false);
    setLoading(false);
    setCurrentSection(null);
    setLoadingSections([]);
    setSuggestions([]);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Function to clear history
  const clearHistory = () => {
    try {
      setHistory([]);
      localStorage.removeItem('legalOracleHistory');
      setShowHistory(false);
      setActionMessage('History cleared');
    } catch (err) {
      console.error('Failed to clear history:', err);
      setError('Failed to clear history');
    }
  };

  // Function to handle new search
  const handleNewSearch = () => {
    setResult(null);
    setQuestion('');
    setError(null);
    setCurrentSection(null);
    setLoadingSections([]);
    setSuggestions([]);
  };

  // Function to handle copy to clipboard
  const handleCopy = async () => {
    if (!result) return;
    
    try {
      await navigator.clipboard.writeText(result.answer);
      setActionMessage('Copied to clipboard');
    } catch (err) {
      console.error('Copy failed:', err);
      setActionMessage('Failed to copy');
    }
  };

  // Function to handle share
  const handleShare = async () => {
    if (!result) return;
    
    const text = `Q: ${result.question}\nA: ${result.answer}`;
    
    try {
      if (navigator.share) {
        await navigator.share({ text });
        setActionMessage('Shared successfully');
      } else {
        await navigator.clipboard.writeText(text);
        setActionMessage('Copied to clipboard');
      }
    } catch (err) {
      console.error('Share failed:', err);
      setActionMessage('Failed to share');
    }
  };

  // Function to toggle theme
  const toggleTheme = () => {
    const newTheme = theme === 'dark' ? 'light' : 'dark';
    setTheme(newTheme);
  };

  // Function to handle font size change
  const toggleFontSize = () => {
    const newFontSize = fontSize === 'text-base' ? 'text-lg' : 'text-base';
    setFontSize(newFontSize);
  };

  // Function to handle suggestion click
  const handleSuggestionClick = (suggestion) => {
    setQuestion(suggestion);
    setSuggestions([]);
  };

  return (
    <div className={`${theme === 'dark' ? 'dark' : ''}`}>
      <div className={`min-h-screen bg-gradient-to-b from-white to-gray-100 dark:from-gray-900 dark:to-gray-800 text-gray-900 dark:text-gray-100 p-4 md:p-6 transition-colors duration-500 ${fontSize}`}>
        <a href="#main-content" className="sr-only focus:not-sr-only">
          Skip to main content
        </a>
        <DisclaimerModal 
          isOpen={showDisclaimer} 
          onClose={handleDisclaimerAccept}
        />
		
        <AboutModal  // Add this modal component
          isOpen={showAbout}
          onClose={() => setShowAbout(false)}
        />		

        {showScrollToTop && (
          <button
            onClick={handleScrollToTop}
            className="fixed bottom-6 right-6 p-3 rounded-full bg-blue-600 text-white shadow-lg hover:bg-blue-700 transition-colors z-50 focus:outline-none focus:ring-2 focus:ring-blue-500"
            aria-label="Scroll to top"
          >
            <ArrowUpCircle className="h-6 w-6" aria-hidden="true" />
          </button>
        )}

        <div className="max-w-4xl mx-auto" id="main-content">
          <header className="text-center mb-8">
            <div className="flex justify-end gap-2">
              <button
                onClick={toggleFontSize}
                className="p-2 rounded-md bg-gray-200 hover:bg-gray-300 dark:bg-gray-700 dark:hover:bg-gray-600 transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500"
                aria-label="Toggle Font Size"
              >
                <span className="text-lg">{fontSize === 'text-base' ? 'A+' : 'A-'}</span>
              </button>
              <button
                onClick={toggleTheme}
                className="p-2 rounded-md bg-gray-200 hover:bg-gray-300 dark:bg-gray-700 dark:hover:bg-gray-600 transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500"
                aria-label="Toggle Theme"
              >
                {theme === 'dark' ? (
                  <Sun className="h-5 w-5 text-yellow-500" aria-hidden="true" />
                ) : (
                  <Moon className="h-5 w-5 text-gray-700" aria-hidden="true" />
                )}
              </button>
            </div>
            <h1 className="text-3xl md:text-4xl font-bold mb-2 flex items-center justify-center gap-2">
              <BookOpen className="h-8 w-8" aria-hidden="true" />
              Legal Oracle
            </h1>
            <p className="text-gray-700 dark:text-gray-400 mb-1">
              Ask questions about South Australian legislation
            </p>
            <StatsDisplay />
          </header>

          {/* Search Form */}
          <form onSubmit={handleSubmit} aria-label="Search form">
            <div className="relative">
              <label htmlFor="question-input" className="sr-only">Ask a question</label>
              <input
                type="text"
                id="question-input"
                value={question}
                onChange={(e) => setQuestion(e.target.value)}
                placeholder={connected ? "Ask a question..." : "Connecting..."}
                className="w-full px-4 py-3 rounded-lg bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-700 text-gray-900 dark:text-white placeholder-gray-500 dark:placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                disabled={loading || !connected}
                autoComplete="off"
                aria-autocomplete="list"
                aria-expanded={suggestions.length > 0}
                aria-owns="suggestions-list"
              />
              {suggestions.length > 0 && (
                <ul
                  id="suggestions-list"
                  className="absolute z-10 w-full bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-700 rounded-lg mt-1 max-h-60 overflow-auto focus:outline-none"
                  role="listbox"
                >
                  {suggestions.map((suggestion, index) => (
                    <li
                      key={index}
                      onClick={() => handleSuggestionClick(suggestion)}
                      className="px-4 py-2 hover:bg-gray-200 dark:hover:bg-gray-700 cursor-pointer text-gray-800 dark:text-gray-200"
                      role="option"
                    >
                      {suggestion}
                    </li>
                  ))}
                </ul>
              )}
              <div className="absolute right-2 top-2 flex gap-2">
                {history.length > 0 && (
                  <Tooltip.Provider>
                    <Tooltip.Root>
                      <Tooltip.Trigger asChild>
                        <button
                          type="button"
                          onClick={() => setShowHistory(!showHistory)}
                          className="p-2 rounded-md bg-gray-200 hover:bg-gray-300 dark:bg-gray-700 dark:hover:bg-gray-600 transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500"
                          aria-label="View History"
                        >
                          <Clock className="h-5 w-5" aria-hidden="true" />
                        </button>
                      </Tooltip.Trigger>
                      <Tooltip.Content
                        side="left"
                        className="bg-gray-800 text-gray-200 text-xs px-2 py-1 rounded shadow-lg"
                      >
                        View History
                      </Tooltip.Content>
                    </Tooltip.Root>
                  </Tooltip.Provider>
                )}
                <Tooltip.Provider>
                  <Tooltip.Root>
                    <Tooltip.Trigger asChild>
                      <button
                        type="submit"
                        disabled={loading || !connected || !question.trim()}
                        className="p-2 rounded-md bg-blue-600 hover:bg-blue-700 disabled:opacity-50 disabled:cursor-not-allowed transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500"
                        aria-label="Search"
                      >
                        {loading ? (
                          <Scale className="h-5 w-5 animate-spin" aria-hidden="true" />
                        ) : (
                          <Search className="h-5 w-5" aria-hidden="true" />
                        )}
                      </button>
                    </Tooltip.Trigger>
                    <Tooltip.Content
                      side="left"
                      className="bg-gray-800 text-gray-200 text-xs px-2 py-1 rounded shadow-lg"
                    >
                      Search
                    </Tooltip.Content>
                  </Tooltip.Root>
                </Tooltip.Provider>
              </div>
            </div>
          </form>

          {/* Error Message */}
          {error && (
            <div className="bg-red-100 dark:bg-red-900/50 border border-red-300 dark:border-red-700 rounded-lg p-4 mb-6" role="alert">
              <p className="text-red-700 dark:text-red-200">{error}</p>
            </div>
          )}

          {/* Connection Status */}
          {!connected && (
            <div className="mt-4 bg-yellow-100 dark:bg-yellow-900/30 border border-yellow-300 dark:border-yellow-700/50 rounded-lg p-4" role="status">
              <div className="flex gap-2 items-center">
                <Loader2 className="h-5 w-5 animate-spin text-yellow-600 dark:text-yellow-500" aria-hidden="true" />
                <p className="text-sm text-yellow-800 dark:text-yellow-200">
                  Connecting to server...
                </p>
              </div>
            </div>
          )}

          {/* Action Message */}
          {actionMessage && (
            <div 
              className="fixed top-4 right-4 bg-gray-800 text-white px-4 py-2 rounded-lg shadow-lg animate-fadeIn" 
              role="alert"
              aria-live="assertive"
            >
              {actionMessage}
            </div>
          )}

		{showHistory && history.length > 0 && (
		  <div className="mb-8 bg-gray-100 dark:bg-gray-800 border border-gray-300 dark:border-gray-700 rounded-lg overflow-hidden animate-fadeIn">
			<div className="p-4 border-b border-gray-300 dark:border-gray-700 flex justify-between items-center">
			  <h3 className="font-semibold">Previous Questions</h3>
			  <button
				onClick={clearHistory}
				className="text-sm text-red-600 dark:text-red-400 hover:text-red-500 dark:hover:text-red-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
			  >
				Clear History
			  </button>
			</div>
			<div className="divide-y divide-gray-300 dark:divide-gray-700">
			  {history.map((item, index) => (
				<button
				  key={index}
				  onClick={() => handleHistoryItemClick(item)}
				  className="w-full p-4 text-left hover:bg-gray-200 dark:hover:bg-gray-700/50 transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500"
				>
				  <p className="text-gray-800 dark:text-gray-300 font-medium">{item.question}</p>
				  <p className="text-sm text-gray-600 dark:text-gray-700 mt-1">
					{new Date(item.timestamp).toLocaleString()}
				  </p>
				</button>
			  ))}
			</div>
		  </div>
		)}
		{showHistory && history.length === 0 && (
		  <p className="text-center text-gray-600 dark:text-gray-400 mt-4">
			No history available yet.
		  </p>
		)}

          {/* Loading State */}
          {loading && !result && (
            <div className="text-center py-8" role="status" aria-live="polite">
              <Scale className="h-8 w-8 animate-spin mx-auto mb-4" aria-hidden="true" />
              <p className="text-gray-700 dark:text-gray-400">{progressMessage}</p>
              <p className="text-sm text-gray-600 dark:text-gray-700 mt-2">
                This may take a few moments.
              </p>
            </div>
          )}

          {/* Results */}
          {result && (
            <div className="space-y-6 animate-fadeIn">
              <div className="flex justify-between items-center">
                <button
                  onClick={handleNewSearch}
                  className="flex items-center gap-2 text-sm text-gray-600 dark:text-gray-400 hover:text-gray-800 dark:hover:text-white transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                  <ArrowLeft className="h-4 w-4" aria-hidden="true" />
                  New Search
                </button>
                <div className="flex gap-2">
                  <Tooltip.Provider>
                    <Tooltip.Root>
                      <Tooltip.Trigger asChild>
                        <button
                          onClick={handleCopy}
                          className="p-2 rounded-md bg-gray-200 hover:bg-gray-300 dark:bg-gray-800 dark:hover:bg-gray-700 transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500"
                          title="Copy to clipboard"
                          aria-label="Copy to clipboard"
                        >
                          <Copy className="h-4 w-4" aria-hidden="true" />
                        </button>
                      </Tooltip.Trigger>
                      <Tooltip.Content
                        side="top"
                        className="bg-gray-800 text-gray-200 text-xs px-2 py-1 rounded shadow-lg"
                      >
                        Copy to Clipboard
                      </Tooltip.Content>
                    </Tooltip.Root>
                  </Tooltip.Provider>
                  <Tooltip.Provider>
                    <Tooltip.Root>
                      <Tooltip.Trigger asChild>
                        <button
                          onClick={handleShare}
                          className="p-2 rounded-md bg-gray-200 hover:bg-gray-300 dark:bg-gray-800 dark:hover:bg-gray-700 transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500"
                          title="Share"
                          aria-label="Share"
                        >
                          <Share2 className="h-4 w-4" aria-hidden="true" />
                        </button>
                      </Tooltip.Trigger>
                      <Tooltip.Content
                        side="top"
                        className="bg-gray-800 text-gray-200 text-xs px-2 py-1 rounded shadow-lg"
                      >
                        Share
                      </Tooltip.Content>
                    </Tooltip.Root>
                  </Tooltip.Provider>
                </div>
              </div>

              <div className="font-medium text-lg text-gray-800 dark:text-gray-300">
                {result.question}
              </div>

				<FormattedAnswer 
				  answer={result.answer} 
				  loadingSections={loadingSections} 
				  result={result}
				  sectionsConfig={sectionsConfig}
				  collapsedSections={collapsedSections}
				  onToggleCollapse={toggleCollapse}
				/>

              {loading && (
                <div className="text-center py-4" role="status" aria-live="polite">
                  <Loader2 className="h-6 w-6 animate-spin mx-auto mb-2" aria-hidden="true" />
                  <p className="text-gray-700 dark:text-gray-400">
                    Generating response...
                  </p>
                </div>
              )}



				<Footer setShowDisclaimer={setShowDisclaimer} setShowAbout={setShowAbout} />

            </div>
          )}

          {/* Example Questions */}
			{!result && !loading && !showHistory && (
			  <div className="mb-8">
				<ExampleQuestionsGrid 
				  onQuestionClick={handleExampleClick} 
				  setShowDisclaimer={setShowDisclaimer}
				  setShowAbout={setShowAbout}
				/>
			  </div>
			)}
        </div>
      </div>
    </div>
  );
}

